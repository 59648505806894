
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Inject } from 'inversify-props';
import {
    ChartData, ChartDataSets, ChartOptions, ChartLegendItem, ChartLegendLabelItem, ChartElementsOptions, ChartLegendOptions,
} from 'chart.js';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import type Day from '@/modules/common/types/day.type';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
// import RatesGraphTooltip from './rates-graph-tooltip.vue';
import ParityService, { ParityServiceS } from '../../parity.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import BML_COLORS from '../../constants/bml-colors';
import TrandTooltip from './trand-tooltip.vue';
import { BmlOptions } from '../../store/parity.store';

@Component({
    components: {
        CustomGraph, LoaderWrapper, TrandTooltip,
    },
})
export default class TrandGraph extends Vue {
    @Inject(ParityServiceS) private parityService!: ParityService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    private chart: Chart | null = null;

    // onTooltipClick(day: Day) {
    //     this.$router.push({ name: 'cars-rates.graph.day-popup', params: { day: String(day) } });
    // }

    beforeDestroy() {
        this.paritySettingsService.pickUpDate = null;
    }

    get filteredDates() {
        const { trendData } = this.parityService;
        const maxTrendLength = 90;

        if (!trendData) return null;

        const today = moment().format('YYYY-MM-DD');
        return Object.keys(trendData)
            .filter(item => moment(item).isAfter(today))
            .concat([today])
            .sort((a, b) => {
                if (moment(a).isBefore(b)) {
                    return -1;
                }
                return 1;
            })
            .slice(0, maxTrendLength);
    }

    get chartData(): ChartData | null {
        const { filteredDates } = this;
        const { trendData, trendDisabled } = this.parityService;

        if (!filteredDates || !trendData) {
            return null;
        }

        const today = moment().format('YYYY-MM-DD');
        const labels = ['Today']
            .concat(filteredDates.filter(date => date !== today))
            .map((item, index) => {
                if (index === 0) {
                    return item;
                }

                return moment(item).format('DD/MM/YYYY');
            });

        const data: { [key: string]: number[] } = {
            B: [],
            M: [],
            L: [],
            A: [],
        };

        const availability: { [key: string]: boolean } = {
            B: !trendDisabled.find(value => value === 'B'),
            M: !trendDisabled.find(value => value === 'M'),
            L: !trendDisabled.find(value => value === 'L'),
            A: !trendDisabled.find(value => value === 'A'),
        };

        filteredDates.forEach(date => {
            if (trendData[date]) {
                data.B.push(
                    ((availability.A && trendData[date].A) || 0)
                    + ((availability.M && trendData[date].M) || 0)
                    + ((availability.L && trendData[date].L) || 0)
                    + ((availability.B && trendData[date].B) || 0),
                );
                data.M.push(
                    ((availability.A && trendData[date].A) || 0)
                    + ((availability.M && trendData[date].M) || 0)
                    + ((availability.L && trendData[date].L) || 0),
                );
                data.L.push(
                    ((availability.A && trendData[date].A) || 0)
                    + ((availability.L && trendData[date].L) || 0),
                );
                data.A.push((availability.A && trendData[date].A) || 0);
            } else {
                data.B.push(0);
                data.M.push(0);
                data.L.push(0);
                data.A.push(0);
            }
        });

        const datasets = [{
            label: 'Sold out',
            data: data.A,
            backgroundColor: BML_COLORS.GRAY,
        }, {
            label: 'Lose',
            data: data.L,
            backgroundColor: BML_COLORS.RED,
        }, {
            label: 'Meet',
            data: data.M,
            backgroundColor: BML_COLORS.GREEN,
        }, {
            label: 'Beat',
            data: data.B,
            backgroundColor: BML_COLORS.YELLOW,
        }];

        return {
            labels,
            datasets,
        };
    }

    get options(): ChartOptions {
        const { trendData } = this.parityService;

        const maxYAxis = trendData ? Object.keys(trendData as any).reduce((acc: number, key: string) => {
            const value = (trendData[key].B || 0) + (trendData[key].M || 0) + (trendData[key].L || 0) + (trendData[key].A || 0);

            if (value > acc) {
                return value;
            }

            return acc;
        }, 0) : 0;

        return {
            responsive: true,
            maintainAspectRatio: false,
            onHover: this.handleHover,
            // @ts-ignore
            elements: {
                point: {
                    radius: this.pointRadius,
                    hitRadius: 5,
                },
            },
            tooltips: {
                intersect: false,
                callbacks: {
                    label: (tooltipItems, data) => {
                        const { datasetIndex, index } = tooltipItems;
                        const labels = data.datasets ? data.datasets.map(set => set.label).reverse() : [];

                        const trendDataDate = (index !== undefined && this.filteredDates) ? this.filteredDates[index] : 0;

                        const values: number[] = [
                            trendData ? trendData[trendDataDate].B || 0 : 0,
                            trendData ? trendData[trendDataDate].M || 0 : 0,
                            trendData ? trendData[trendDataDate].L || 0 : 0,
                            trendData ? trendData[trendDataDate].A || 0 : 0,
                        ];

                        return labels.map((label, i) => `${label}: ${values[i]}`);
                    },
                },
                custom: tooltip => {
                    if (!tooltip) return;
                    // disable displaying the color box;
                    // eslint-disable-next-line
                    tooltip.displayColors = false;
                },
            },
            legend: {
                display: true,
                position: 'bottom',
                reverse: true,
                onClick: this.handleLegendClick,
                onHover: this.handleHoverLegend,
                labels: {
                    padding: 20,
                    usePointStyle: true,
                    boxWidth: 8,
                    fontSize: 12,
                },
            },
            // @ts-ignore
            scales: {
                yAxes: [{
                    ticks: {
                        max: maxYAxis * 1.2,
                        min: 0,
                        stepSize: Math.round(maxYAxis / 50) * 10,
                        padding: 10,
                    },
                    afterBuildTicks: (_, ticks: number[]) => {
                        ticks.shift();
                        return ticks;
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Number of cases',
                    },
                }],
                xAxes: [{
                    ticks: {
                        padding: 10,
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                }],
            },
            onClick: this.handleChartClick,
        };
    }

    get availability() {
        const { trendDisabled } = this.parityService;

        const availability: { [key: string]: boolean } = {
            B: !trendDisabled.find(value => value === 'B'),
            M: !trendDisabled.find(value => value === 'M'),
            L: !trendDisabled.find(value => value === 'L'),
            A: !trendDisabled.find(value => value === 'A'),
        };

        return availability;
    }

    get maxYAxis() {
        const { trendData } = this.parityService;

        const maxYAxis = trendData ? Object.keys(trendData as any).reduce((acc: number, key: string) => {
            const value = ((this.availability.B && trendData[key].B) || 0)
                + ((this.availability.M && trendData[key].M) || 0)
                + ((this.availability.L && trendData[key].L) || 0)
                + ((this.availability.A && trendData[key].A) || 0);

            if (value > acc) {
                return value;
            }

            return acc;
        }, 0) : 0;

        return maxYAxis;
    }

    pointRadius(context: any) {
        const { pickUpDate } = this.paritySettingsService;

        if (!this.filteredDates || !pickUpDate) {
            return 0;
        }

        const pickUpDateIndex = this.filteredDates.findIndex(date => (
            date === moment(this.paritySettingsService.pickUpDate).format('YYYY-MM-DD')));

        return context.dataIndex === pickUpDateIndex ? 4 : 0;
    }

    handleDraw(chart: Chart) {
        this.chart = chart;
    }

    handleChartClick(event: MouseEvent, point: {_index: number}[]) {
        if (!point[0] || !this.filteredDates || !this.chart) {
            return;
        }

        // eslint-disable-next-line no-underscore-dangle
        const xIndex = point[0]._index;

        const date = new Date(this.filteredDates[xIndex]);

        if (
            this.paritySettingsService.pickUpDate
            && this.paritySettingsService.pickUpDate.toString() === date.toString()
        ) {
            this.paritySettingsService.pickUpDate = null;
        } else {
            this.paritySettingsService.pickUpDate = date;
        }

        this.chart.update();
    }

    handleLegendClick(event: MouseEvent, legendItem: ChartLegendLabelItem) {
        const { trendDisabled } = this.parityService;
        const bmlValues: BmlOptions[] = [BmlOptions.B, BmlOptions.M, BmlOptions.L, BmlOptions.A];
        const disabledSet = new Set(trendDisabled);

        if (!this.chart || !this.chart.data || !this.chart.data.datasets) {
            return;
        }

        const index = legendItem.datasetIndex;

        if (index === undefined) {
            return;
        }

        const meta = this.chart.getDatasetMeta(index);

        meta.hidden = !meta.hidden ? !this.chart.data.datasets[index].hidden : false;

        if (meta.hidden) {
            disabledSet.add(bmlValues[(bmlValues.length - 1) - index]);
        } else {
            disabledSet.delete(bmlValues[(bmlValues.length - 1) - index]);
        }

        this.parityService.trendDisabled = Array.from(disabledSet);

        if (
            this.chart.options.scales
            && this.chart.options.scales.yAxes
            && this.chart.options.scales.yAxes[0]
            && this.chart.options.scales.yAxes[0].ticks
        ) {
            this.chart.options.scales.yAxes[0].ticks.max = this.maxYAxis;
            this.chart.options.scales.yAxes[0].ticks.stepSize = Math.round(this.maxYAxis / 50) * 10;
        }

        this.chart.update();
    }

    handleHover(event: Event, chartElement: ChartElementsOptions[]) {
        if (!event || !event.target) {
            return;
        }

        const target = event.target as HTMLElement;

        target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }

    handleHoverLegend(event: Event, legendElement: ChartLegendLabelItem) {
        if (!event || !event.target) {
            return;
        }

        const target = event.target as HTMLElement;

        target.style.cursor = legendElement ? 'pointer' : 'default';
    }

    borderColor(companyName: string) {
        return '#333333';
    }

    colorByHotel(companyName: string) {
        return '#555555';
    }
}
