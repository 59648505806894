
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { Inject } from 'inversify-props';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';

@Component
export default class BmlTableInfo extends Vue {
    @Inject(ParityServiceS) private parityService!: ParityService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    get total() {
        return this.parityService.tableCount;
    }

    // get scanDate() {
    //     if (!this.paritySettingsService.scanDate) {
    //         return null;
    //     }

    //     return moment(this.paritySettingsService.scanDate).format('DD MMMM YYYY');
    // }
}
